.footer {
    background-color: white;
    padding-bottom: 24px;
    padding-top: 16px;
}

.trademarkContainer {
    padding-top: 32px;
}

.trademark {
    color: #999999;
    font-family: Roboto Serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.39px;
    letter-spacing: 0.5px;
    text-align: center;
}