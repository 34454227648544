.titles {
    font-family: Roboto Serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 56.21px;
    letter-spacing: 0.5px;
    text-align: center;

    color: #FFFFFF;

    text-shadow: rgba(0, 0, 0, 0.25) 0px 16px 16px;
}

.stores {
    margin-top: 24px;
}

.storeCurve {
    background-color: white;
}

.storeContainer {
    border-radius: 40% 30% 0% 0% / 25% 0% 0% 25%;
    background-color: #002E10;
    padding-bottom: 32px;
    padding-top: 64px;
}