.title {
    font-family: Roboto Serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 56.21px;
    letter-spacing: 0.5px;
    text-align: center;

    color: #FFFFFF;

    text-shadow: rgba(0, 0, 0, 0.25) 0px 16px 16px;
}

.subtitle {
    margin-top: 4px;

    font-family: Roboto Serif;
    font-size: 17px;
    font-weight: 300;
    line-height: 19.91px;
    letter-spacing: 0.5px;
    text-align: center;

    color: #FFFFFF;
}


.knowMoreContainer {
    margin-top: 32px;
    margin-bottom: 24px;
    --md-sys-color-primary: var(--Schemes-On-Primary, rgba(255, 255, 255, 1));
    --md-sys-color-on-primary: var(--Schemes-Surface-Tint, rgba(54, 105, 62, 1));
}

.knowMoreButton {
    font-family: Roboto Serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    height: 56px;
}

.slogan {
    padding-left: 4px;
    padding-right: 4px;
}

.sloganCurve {
    background-image: url(bg.svg);
    border-radius: 40% 80% 0% 0% / 40% 80% 0% 0%;
    height: 80px;
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
}