.emailList {
    background: rgba(231, 239, 212, 1);
    padding-top: 64px;
    padding-bottom: 32px;
}

.signInText {
    font-family: Roboto Serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 28.1px;
    letter-spacing: 0.5px;
    text-align: center;
    color: rgba(0, 46, 16, 1);
}

.emailContainer {
    padding: 32px;
    --md-sys-typescale-body-large: 400 1rem system-ui;
    --md-sys-color-primary: rgba(25, 144, 65, 1);
    --md-sys-color-surface-container-highest: #ffffff;
    --md-outlined-text-field-label-text-color: rgba(0, 46, 16, 1);
    --md-outlined-text-field-input-text-color: #161d1d;
}

